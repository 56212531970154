/*
 * @Author: kok-s0s
 * @Date: 2021-08-08 17:00:36
 * @LastEditors: kok-s0s
 * @LastEditTime: 2021-08-09 01:57:21
 * @Description:
 */
import React from "react";
import "./App.css";
import marked from "marked";
import Prism from "prismjs";

// ALLOWS LINE BREAKS WITH RETURN BUTTON
marked.setOptions({
	breaks: true,
	highlight: function (code) {
		return Prism.highlight(code, Prism.languages.javascript);
	},
});

// INSERTS target="_blank" INTO HREF TAGS (required for Codepen links)
const renderer = new marked.Renderer();
renderer.link = function (href, title, text) {
	return `<a target="_blank" href="${href}">${text}</a>`;
};

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			markdown: placeholder,
			editorMaximized: false,
			previewMaximized: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleEditorMaximize = this.handleEditorMaximize.bind(this);
		this.handlePreviewMaximize = this.handlePreviewMaximize.bind(this);
	}
	handleChange(e) {
		this.setState({
			markdown: e.target.value,
		});
	}
	handleEditorMaximize() {
		this.setState({
			editorMaximized: !this.state.editorMaximized,
		});
	}
	handlePreviewMaximize() {
		this.setState({
			previewMaximized: !this.state.previewMaximized,
		});
	}
	render() {
		const classes = this.state.editorMaximized
			? ["editorWrap maximized", "previewWrap hide", "fa fa-compress"]
			: this.state.previewMaximized
			? ["editorWrap hide", "previewWrap maximized", "fa fa-compress"]
			: ["editorWrap", "previewWrap", "fa fa-arrows-alt"];
		return (
			<div>
				<div className={classes[0]}>
					<Toolbar
						icon={classes[2]}
						onClick={this.handleEditorMaximize}
						text="Editor"
					/>
					<Editor
						markdown={this.state.markdown}
						onChange={this.handleChange}
					/>
				</div>
				<div className="converter" />
				<div className={classes[1]}>
					<Toolbar
						icon={classes[2]}
						onClick={this.handlePreviewMaximize}
						text="Previewer"
					/>
					<Preview markdown={this.state.markdown} />
				</div>
			</div>
		);
	}
}

const Toolbar = (props) => {
	return (
		<div className="toolbar">
			<i className="fa fa-free-code-camp" title="no-stack-dub-sack" />
			{props.text}
			<i className={props.icon} onClick={props.onClick} />
		</div>
	);
};

const Editor = (props) => {
	return (
		<textarea
			id="editor"
			onChange={props.onChange}
			type="text"
			value={props.markdown}
		/>
	);
};

const Preview = (props) => {
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: marked(props.markdown, { renderer: renderer }),
			}}
			id="preview"
		/>
	);
};

const placeholder = `
# Hello, React!

**你可以在此使用markdown来编写文章。**
`;
export default App;
